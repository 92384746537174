import { cx } from '~/utils'

import * as containerStyles from './PostSlideTab.module.scss'

const PostSlideTab = ({ active, titleProgress, onMouseEnter }) => (
  <div
    key={titleProgress}
    onMouseEnter={onMouseEnter}
    className={cx(
      containerStyles.postSliderTab__wrapper,
      active && containerStyles.postSliderTab__active,
    )}
  >
    <div className={containerStyles.postSliderTab__line}>
      <div className={containerStyles.postSliderTab__progress} />
    </div>
    <div className={containerStyles.postSliderTab__title}>{titleProgress}</div>
  </div>
)

export default PostSlideTab
