import { getImage } from 'gatsby-plugin-image'
import { useState, useRef } from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import PostSlide from '~/components/shared/PostBanner'
import { PostsSliderMock } from '~/types/mock'
import { cx, fileToImageLikeData } from '~/utils'

import PostSlideTab from './components/PostSlideTab'
import * as containerStyles from './PostsSlider.module.scss'
import usePostsSliderStaticQuery from './usePostsSliderStaticQuery'
import './PostsSlider.scss'

interface PostsSliderProps {
  dataContent: PostsSliderMock[]
}

const PostsSlider = ({ dataContent }: PostsSliderProps) => {
  const swiperRef = useRef<(typeof Swiper & { swiper: any }) | null>(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [, setHoveredTab] = useState(null)
  const query = usePostsSliderStaticQuery()

  const images = {
    postMvp: getImage(fileToImageLikeData(query.postMvp)),
    postSaas: getImage(fileToImageLikeData(query.postSaas)),
    postMarketplace: getImage(fileToImageLikeData(query.postMarketplace)),
  }

  const handleChange = (swiper) => {
    setActiveSlideIndex(swiper.realIndex)
  }

  const handleHover = (index) => {
    setHoveredTab(index)
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index)
      setActiveSlideIndex(index)
    }
  }

  return (
    <div className="col-12">
      <div className={cx(containerStyles.postSwiperSlider, 'postSwiperSlider')}>
        <Swiper
          ref={swiperRef}
          loop={false}
          speed={750}
          spaceBetween={30}
          navigation={false}
          allowTouchMove={true}
          centeredSlides={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          onSlideChange={(swiper) => handleChange(swiper)}
        >
          {dataContent.map(
            (
              { image, title, link, postDate, readTime, description },
              index,
            ) => (
              <SwiperSlide
                key={title}
                className={
                  activeSlideIndex === index ? 'swiper-slide-active' : ''
                }
              >
                <PostSlide
                  postDate={postDate}
                  readTime={readTime}
                  link={link}
                  title={title}
                  image={images[image]}
                  description={description}
                  loading={index === 0 ? 'eager' : 'lazy'}
                />
              </SwiperSlide>
            ),
          )}

          <div className={containerStyles.postSwiperSlider__tab}>
            {dataContent.map(({ titleProgress }, index) => (
              <PostSlideTab
                key={titleProgress}
                titleProgress={titleProgress}
                active={activeSlideIndex === index}
                onMouseEnter={() => handleHover(index)}
              />
            ))}
          </div>
        </Swiper>
      </div>
    </div>
  )
}

export default PostsSlider
