import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const usePostsSliderStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      postMvp: file(relativePath: { eq: "slider/slider-post-mvp.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      postSaas: file(relativePath: { eq: "slider/slider-post-saas.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
      postMarketplace: file(
        relativePath: { eq: "slider/slider-post-marketplace.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default usePostsSliderStaticQuery
