import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import SubscribeBlogModal from '~/components/forms/SubscribeBlogModal'
import ModalBlock from '~/components/shared/ModalBlock'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './BlogSubscribeModal.module.scss'

type BlogSubscribeModalProps = {
  show: boolean
  onHide: () => void
}

const BlogSubscribeModal = ({ show, onHide }: BlogSubscribeModalProps) => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      subscribeModalImage: file(
        relativePath: { eq: "modal/subscribe-blog.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const subscribeModalImage = getImage(
    fileToImageLikeData(query.subscribeModalImage),
  )

  return (
    <ModalBlock
      show={show}
      onHide={onHide}
      className={containerStyles.blogSubscribeModal}
      classNameHeader={containerStyles.blogSubscribeModal__close}
    >
      <div className={containerStyles.blogSubscribeModal__wrapper}>
        <div className={containerStyles.blogSubscribeModal__image}>
          <GatsbyImage
            image={subscribeModalImage as IGatsbyImageData}
            alt="Subscribe Now | Codica"
            title="Subscribe Now | Codica"
          />
        </div>

        <div className={containerStyles.blogSubscribeModal__text}>
          Monthly updates on MVP, SaaS and Marketplace development -
          <span className="ml5">Subscribe Now!</span>
        </div>

        <ReCaptchaProvider>
          <SubscribeBlogModal onSubmitSuccess={onHide} />
        </ReCaptchaProvider>
      </div>
    </ModalBlock>
  )
}

export default BlogSubscribeModal
