import { graphql, PageProps } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import {
  AllStrapiArticle,
  AllStrapiCategory,
  AllStrapiTag,
  StrapiPage,
} from '~/types/graphql'
import { PagePropsWithContext } from '~/types/helper'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import BlogView from '~/views/Blog/Blog.view'

export type BlogQueryResult = StrapiPage &
  AllStrapiArticle &
  AllStrapiCategory &
  AllStrapiTag

export const Head = ({
  data,
  pageContext,
}: PagePropsWithContext<BlogQueryResult>) => {
  const { humanPageNumber, previousPagePath, nextPagePath } = pageContext
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  const seoNumPage = humanPageNumber > 1 ? ` - Page ${humanPageNumber}` : ''
  const seoUrl =
    humanPageNumber === 1
      ? 'https://www.codica.com/blog/'
      : `https://www.codica.com/blog/page/${humanPageNumber}/`

  return (
    <>
      {previousPagePath && (
        <link rel="prev" href={`https://www.codica.com${previousPagePath}`} />
      )}
      {nextPagePath && (
        <link rel="next" href={`https://www.codica.com${nextPagePath}`} />
      )}

      <SEO
        title={`${meta_title}${seoNumPage}`}
        description={`${meta_description}${seoNumPage}`}
        urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
        url={seoUrl}
        meta={[
          {
            name: 'robots',
            content:
              'max-image-preview:large, max-snippet:-1, max-video-preview:-1',
          },
        ]}
      />
    </>
  )
}

const Blog = ({ data, pageContext }: PageProps<BlogQueryResult>) => (
  <BlogView data={data} pageContext={pageContext} />
)

export default Blog

export const pageQuery = graphql`
  query ($published: [Boolean], $skip: Int!, $limit: Int!) {
    strapiPage(page_name: { eq: "Blog" }) {
      ...MetaTagInformationPages
    }

    allStrapiArticle(
      sort: { modified_date: DESC }
      filter: { active: { eq: true }, published: { in: $published } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          publication_date(formatString: "DD MMM YYYY")
          modified_date(formatString: "DD MMM YYYY")
          id
          url
          published
          body {
            data {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
          strapi_id
          views
          preview_image_alt
          category {
            name
          }
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
          title
          meta_description
        }
      }
    }

    allStrapiCategory(sort: { order: ASC }) {
      edges {
        node {
          name
          nav_name
          id
        }
      }
    }

    allStrapiTag(sort: { number_tag: ASC }) {
      edges {
        node {
          name
          url
          articles {
            active
          }
        }
      }
    }
  }
`
