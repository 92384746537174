import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs'
import MainLayout from '~/components/layouts/MainLayout'
import BlogSubscribeModal from '~/components/modals/BlogSubscribeModal'
import PostsSlider from '~/components/sliders/PostsSlider'
import usePopup from '~/hooks/usePopup'
import PostsSliderMock from '~/mock/PostsSlider'
import { BlogQueryResult } from '~/templates/blog/Blog'
import { PagePropsWithContext } from '~/types/helper'
import BlogPagination from '~/views/Blog/components/BlogPagination'
import BlogPostList from '~/views/Blog/components/BlogPostList'
import NavigationCategory from '~/views/Blog/components/NavigationCategory'
import NavigationTag from '~/views/Blog/components/NavigationTag'

import * as containerStyles from './Blog.module.scss'

const BlogView = ({
  data,
  pageContext,
}: PagePropsWithContext<BlogQueryResult>) => {
  const {
    allStrapiArticle: { edges },
  } = data

  const articles = edges.map((article) => article.node)

  const {
    handleCloseIdlePopup,
    popupState: { showIdlePopup },
  } = usePopup('subscribeBlog', 20 * 1000)

  return (
    <MainLayout isStaticChat scrollTop>
      <div className={containerStyles.blog}>
        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <div className={containerStyles.blog__breadcrumbsWrapper}>
                <Breadcrumbs current="Blog" currentPath="/blog/" />
              </div>

              <h1 className={containerStyles.blog__title}>Blog</h1>
            </div>

            <NavigationCategory data={data} />

            <PostsSlider dataContent={PostsSliderMock} />

            <NavigationTag data={data} />

            <BlogPostList data={articles} withCardEbook withHeading />

            <BlogPagination pageContext={pageContext} />
          </div>
        </div>
      </div>

      <BlogSubscribeModal show={showIdlePopup} onHide={handleCloseIdlePopup} />
    </MainLayout>
  )
}

export default BlogView
